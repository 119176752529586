import {
    NotificationComponent as SourceNotification,
} from 'SourceComponent/Notification/Notification.component';

import './Notification.override.style';

/** @namespace Pwa/Component/Notification/Component */
export class NotificationComponent extends SourceNotification {
    // TODO implement logic
}

export default NotificationComponent;
