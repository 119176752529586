/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable consistent-return */
import parser from 'html-react-parser';
import attributesToProps from 'html-react-parser/lib/attributes-to-props';
import domToReact from 'html-react-parser/lib/dom-to-react';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import BannerModal from 'Component/BannerModal';
import CountdownTimer from 'Component/CountdownTimer';
import Image from 'Component/Image';
import Link from 'Component/Link';
import WidgetFactory from 'Component/WidgetFactory';
import { hash } from 'Util/Request/Hash';

/**
 * Html content parser
 * Component converts HTML strings to React components
 * @class Html
 * @namespace Pwa/Component/Html/Component */
export class HtmlComponent extends PureComponent {
    static propTypes = {
        content: PropTypes.string.isRequired,
    };

    createdOutsideElements = {};

    rules = [
        {
            query: { name: ['widget'] },
            replace: this.replaceWidget,
        },
        {
            query: { name: ['a'] },
            replace: this.replaceLinks,
        },
        {
            query: { name: ['img'] },
            replace: this.replaceImages,
        },
        {
            query: { name: ['input'] },
            replace: this.replaceInput,
        },
        {
            query: { name: ['script'] },
            replace: this.replaceScript,
        },
        {
            query: { name: ['style'] },
            replace: this.replaceStyle,
        },
        {
            query: { name: ['table'] },
            replace: this.wrapTable,
        },
        {
            query: { name: ['iframe'] },
            replace: this.replaceIframe,
        },
        {
            query: { attribs: ['mc-modal-banner', 'mc-modal-id', 'mc-modal-type'] },
            replace: this.replaceModalBanner,
        },
        {
            query: {
                attribs: [
                    'mc-countdown-date',
                    'mc-countdown-type',
                    'mc-countdown-style',
                    'mc-countdown-units',
                    'mc-countdown-days',
                ],
            },
            replace: this.replaceCountDown,
        },
    ];

    parserOptions = {
        replace: (domNode) => {
            const { data, name: domName, attribs: domAttrs } = domNode;

            // Let's remove empty text nodes
            if (data && !data.replace(/\u21b5/g, '').replace(/\s/g, '').length) {
                return <></>;
            }

            const rule = this.rules.find((rule) => {
                const { query: { name, attribs } } = rule;

                if (name && domName && name.indexOf(domName) !== -1) {
                    return true;
                }

                if (attribs && domAttrs) {
                    for (let i = 0; i < attribs.length; i++) {
                        const attrib = attribs[i];

                        if (typeof attrib === 'object') {
                            const queryAttrib = Object.keys(attrib)[0];

                            if (Object.prototype.hasOwnProperty.call(domAttrs, queryAttrib)) {
                                return domAttrs[queryAttrib].match(Object.values(attrib)[0]);
                            }
                        } else if (Object.prototype.hasOwnProperty.call(domAttrs, attrib)) {
                            return true;
                        }
                    }
                }

                return false;
            });

            if (rule) {
                const { replace } = rule;

                return replace.call(this, domNode);
            }
        },
    };

    replaceIframe(elem) {
        const { children, attribs } = elem;

        return (
            <iframe
              title="iframe"
              { ...attributesToProps(attribs) }
            >
                { domToReact(children, this.parserOptions) }
            </iframe>
        );
    }

    replaceModalBanner(elem) {
        const { children, attribs } = elem;

        const {
            'mc-modal-banner': banner,
            'mc-modal-id': id,
            'mc-modal-type': type,
            ...attrs
        } = attribs;

        return (
            <BannerModal
              { ...attributesToProps({
                  ...attrs, id, banner, type,
              }) }
            >
                { domToReact(children, this.parserOptions) }
            </BannerModal>
        );
    }

    replaceCountDown(elem) {
        const { attribs } = elem;
        const {
            'mc-countdown-date': date,
            'mc-countdown-type': type,
            'mc-countdown-style': mods,
            'mc-countdown-units': isFull,
            'mc-countdown-days': isWithoutDays,
            ...attrs
        } = attribs;

        return (
            <CountdownTimer
              { ...attributesToProps({
                  ...attrs, date, type, mods, isFull, isWithoutDays,
              }) }
            />
        );
    }

    attributesToProps(attribs) {
        const toCamelCase = (string) => string.replace(/_[a-z]/g, (match) => match.substr(1).toUpperCase());

        const convertPropertiesToValidFormat = (properties) => Object.entries(properties)
            .reduce((validProps, [key, value]) => {
                // eslint-disable-next-line no-restricted-globals
                if (!isNaN(value)) {
                    return { ...validProps, [toCamelCase(key)]: +value };
                }

                return { ...validProps, [toCamelCase(key)]: value };
            }, {});

        const properties = convertPropertiesToValidFormat(attribs);

        return attributesToProps(properties);
    }

    replaceLinks({ attribs, children }) {
        const { href, ...attrs } = attribs;

        if (href) {
            const isAbsoluteUrl = (value) => new RegExp('^(?:[a-z]+:)?//', 'i').test(value);
            const isSpecialLink = (value) => new RegExp('^(sms|tel|mailto):', 'i').test(value);

            if (!isAbsoluteUrl(href) && !isSpecialLink(href)) {
                return (
                    <Link { ...attributesToProps({ ...attrs, to: href }) }>
                        { domToReact(children, this.parserOptions) }
                    </Link>
                );
            }
        }
    }

    replaceImages({ attribs }) {
        const attributes = attributesToProps(attribs);

        if (attribs.src) {
            return <Image { ...attributes } />;
        }
    }

    replaceInput({ attribs }) {
        return <input { ...attributesToProps(attribs) } />;
    }

    wrapTable({ attribs, children }) {
        return (
            <div block="Table" elem="Wrapper">
                <table { ...attributesToProps(attribs) }>
                    { domToReact(children, this.parserOptions) }
                </table>
            </div>
        );
    }

    replaceWidget({ attribs }) {
        return <WidgetFactory { ...this.attributesToProps(attribs) } />;
    }

    replaceStyle(elem) {
        const { children } = elem;
        const elemHash = hash(elem);

        if (this.createdOutsideElements[elemHash]) {
            return <></>;
        }

        const style = document.createElement('style');

        if (children && children[0]) {
            style.appendChild(document.createTextNode(children[0].data));
        }

        document.head.appendChild(style);
        this.createdOutsideElements[elemHash] = true;

        return <></>;
    }

    replaceScript(elem) {
        const { attribs, children } = elem;
        const { src = '' } = attribs;
        const scriptContent = children[0] ? children[0].data : '';
        const elemHash = hash(src + scriptContent);

        if (this.createdOutsideElements[elemHash]) {
            return <></>;
        }

        const script = document.createElement('script');

        Object.entries(attribs).forEach(([attr, value]) => script.setAttribute(attr, value));

        if (children && children[0]) {
            script.appendChild(document.createTextNode(children[0].data));
        }

        document.head.appendChild(script);
        this.createdOutsideElements[elemHash] = true;

        return <></>;
    }

    render() {
        const { content } = this.props;

        return parser(content, this.parserOptions);
    }
}

export default HtmlComponent;
