import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { ChildrenType } from 'Type/Common.type';

/** @namespace Pwa/Component/IfStore/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    systemStoreCode: state.ConfigReducer.code,
});

/** @namespace Pwa/Component/IfStore/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({
    // addProduct: options => CartDispatcher.addProductToCart(dispatch, options)
});

/** @namespace Pwa/Component/IfStore/Container */
export class IfStoreContainer extends PureComponent {
    static propTypes = {
        systemStoreCode: PropTypes.string.isRequired,
        storeCode: PropTypes.string.isRequired,
        children: ChildrenType.isRequired,
    };

    containerFunctions = {
        // getData: this.getData.bind(this)
    };

    containerProps = () => {

    };

    render() {
        const { storeCode, systemStoreCode, children } = this.props;

        if (systemStoreCode !== storeCode) {
            return null;
        }

        return children;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(IfStoreContainer);
