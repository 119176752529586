/**
 * This is a full replacement of Router component
 * We could not extend due to side effect in the original component
 * dispatches were sent from __construct generating two calls for each initialization in case of
 * extended component.
 *
 * Bad Scandi! Bad Scandi!
 */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
} from 'SourceComponent/Router/Router.container';
import { isMobile, isMobileClientHints, isUsingClientHints } from 'Util/Mobile';
import { waitForPriorityLoad } from 'Util/Request/LowPriorityLoad';

import Router from './Router.component';

/** @namespace Pwa/Component/Router/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
});

/** @namespace Pwa/Component/Router/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    loadMenu: () =>
        waitForPriorityLoad().then(
            /** @namespace Pwa/Component/Router/Container/mapDispatchToProps/waitForPriorityLoad/then */
            () => {
                import(
                /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
                    'Store/McMenu/McMenu.dispatcher'
                ).then(
                    /** @namespace Pwa/Component/Router/Container/mapDispatchToProps/waitForPriorityLoad/then/then */
                    ({ default: dispatcher }) => dispatcher.handleData(dispatch, {})
                );
            }
        ),
});

/** @namespace Pwa/Component/Router/Container */
export class RouterContainer extends PureComponent {
    static propTypes = {
        init: PropTypes.func.isRequired,
        updateMeta: PropTypes.func.isRequired,
        updateConfigDevice: PropTypes.func.isRequired,
        loadMenu: PropTypes.func.isRequired,
        base_link_url: PropTypes.string,
        default_description: PropTypes.string,
        default_keywords: PropTypes.string,
        default_title: PropTypes.string,
        title_prefix: PropTypes.string,
        title_suffix: PropTypes.string,
        isLoading: PropTypes.bool,
        isBigOffline: PropTypes.bool,
        meta_title: PropTypes.string,
        status_code: PropTypes.string,
    };

    static defaultProps = {
        base_link_url: '',
        default_description: '',
        default_keywords: '',
        default_title: '',
        title_prefix: '',
        title_suffix: '',
        isLoading: true,
        isBigOffline: false,
        meta_title: '',
        status_code: '',
    };

    __construct(props) {
        super.__construct(props);

        this.state = {
            currentUrl: window.location.pathname,
        };

        this.initializeApplication();
        this.redirectFromPartialUrl();
        this.handleResize();
        this.initMcMenu();
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);

    // history.listen((location, action) => {
    //     if (action === 'PUSH' || action === 'POP') {
    //         window?.dataLayer = [];
    //     }
    // });
    }

    componentDidUpdate(prevProps) {
        const { isLoading, updateMeta } = this.props;
        const { isLoading: prevIsLoading } = prevProps;

        if (!isLoading && isLoading !== prevIsLoading) {
            const {
                default_description,
                default_keywords,
                default_title,
                title_prefix,
                title_suffix,
                meta_title,
                status_code,
            } = this.props;

            updateMeta({
                default_title,
                // title: meta_title || default_title,
                title: meta_title, // FIX problemi di SEO alla prima apertura di pagina
                default_description,
                description: default_description,
                default_keywords,
                keywords: default_keywords,
                title_prefix,
                title_suffix,
                status_code,
            });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    initMcMenu() {
        const { loadMenu } = this.props;
        loadMenu();
    }

    handleResize = async () => {
        const { updateConfigDevice } = this.props;

        if (isUsingClientHints) {
            const { platform, model } = await isMobileClientHints.getDeviceData();
            updateConfigDevice({
                isMobile: isMobile.any(),
                android: isMobile.android(platform),
                ios: isMobile.iOS(platform),
                blackberry: isMobile.blackBerry(model),
                opera: isMobile.opera(model),
                safari: isMobile.safari(model),
                windows: isMobile.windows(model),
            });
        } else {
            updateConfigDevice({
                isMobile: isMobile.any(),
                android: isMobile.android(),
                ios: isMobile.iOS(),
                blackberry: isMobile.blackBerry(),
                opera: isMobile.opera(),
                safari: isMobile.safari(),
                windows: isMobile.windows(),
            });
        }
    };

    containerProps = () => {
        const { isBigOffline } = this.props;

        return { isBigOffline };
    };

    initializeApplication() {
        const { init } = this.props;
        init();
    }

    redirectFromPartialUrl() {
        const { base_link_url } = this.props;
        const { pathname: storePrefix } = new URL(
            base_link_url || window.location.origin
        );
        const { pathname } = location;

        if (storePrefix === '/') {
            return;
        }

        if (storePrefix.slice(0, -1) === pathname) {
            history.replace(storePrefix);
        }
    }

    render() {
        return <Router { ...this.containerProps() } />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RouterContainer);
