import {
    CLEAR_CATEGORY_FILTER,
    CLOSE_CATEGORY_FILTERS,
    OPEN_CATEGORY_FILTERS,
    SET_CATEGORY_ATTRIBUTE_SET_FILTER,
    SET_CATEGORY_FILTER,
} from './CategoryFilters.action';

/** @namespace Pwa/Store/CategoryFilters/Reducer/getInitialState */
export const getInitialState = () => ({
    isShowCategoryFilters: false,
    selectedFilters: {},
    selectedAttributeSets: undefined,
    selectedAttributeSetFilter: {},
});

/** @namespace Pwa/Store/CategoryFilters/Reducer/CategoryFiltersReducer */
export const CategoryFiltersReducer = (
    state = getInitialState(),
    action
) => {
    const { type, payload } = action;
    switch (type) {
    case OPEN_CATEGORY_FILTERS:
        return {
            ...state,
            isShowCategoryFilters: true,
        };

    case CLOSE_CATEGORY_FILTERS:
        return {
            ...state,
            isShowCategoryFilters: false,
        };

    case SET_CATEGORY_FILTER:
        const { filterName, filterArray } = payload;

        const filters = { ...state.selectedFilters };

        if (filterName in state.selectedFilters) {
            if (filterArray.length) {
                filterArray.forEach((val) => {
                    if (filterName === 'price') {
                        filters[filterName] = [];
                    }

                    if (filters[filterName].includes(val)) {
                        filters[filterName] = filters[filterName].filter((e) => e !== val);
                    } else {
                        filters[filterName].push(val);
                    }
                });
            } else {
                filters[filterName] = [];
            }
        } else {
            filters[filterName] = filterArray;
        }

        return {
            ...state,
            selectedFilters: filters,
        };

    case SET_CATEGORY_ATTRIBUTE_SET_FILTER:
        return {
            ...state,
            selectedAttributeSetFilter: payload,
        };

    case CLEAR_CATEGORY_FILTER:
        return {
            ...state,
            selectedFilters: {},
        };

    default:
        return state;
    }
};

export default CategoryFiltersReducer;
