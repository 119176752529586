import { ChildrenType } from '@scandipwa/scandipwa/src/type/Common.type';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { customerType } from 'Type/Account.type';

export const GTMDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/GTM/GTM.dispatcher'
);

/** @namespace Pwa/Component/Gtm/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    customer: state.MyAccountReducer.customer,
});

/** @namespace Pwa/Component/Gtm/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    pushDlMain: (customer, pageType) => GTMDispatcher.then(
        ({ default: dispatcher }) => dispatcher.pushDlMain(dispatch, customer, pageType)
    ),
    pushInit: (pageType) => GTMDispatcher.then(
        ({ default: dispatcher }) => dispatcher.pushInit(dispatch, pageType)
    ),
});

/** @namespace Pwa/Component/Gtm/Container */
export class GtmContainer extends PureComponent {
    static propTypes = {
        children: ChildrenType.isRequired,
        pushDlMain: PropTypes.func.isRequired,
        pushInit: PropTypes.func.isRequired,
        customer: customerType.isRequired,
        pageType: PropTypes.string.isRequired,
    };

    async componentDidMount() {
        const {
            pageType, customer, pushDlMain, pushInit,
        } = this.props;

        await pushDlMain(customer, pageType);
        await pushInit(pageType);
    }

    async componentDidUpdate(prevProps) {
        const {
            pageType: newType, customer, pushDlMain, pushInit,
        } = this.props;
        const { pageType: oldType } = prevProps;

        if (oldType !== newType) {
            await pushDlMain(customer, newType);
            await pushInit(newType);
        }
    }

    render() {
        const { children } = this.props;

        return children;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GtmContainer);
