import { createRoot } from 'react-dom/client';

import App from 'Component/App';
import { waitForElm } from 'Component/Config';

import 'Style/main';

if ('caches' in window) {
    caches.keys().then(/** @namespace Pwa/Render/keys/then */
        (names) => {
            // Delete all the cache files
            names.forEach((name) => {
                caches.delete(name);
            });
        },
    );
}

if ('serviceWorker' in navigator) {
    const swUrl = '/service-worker.js';
    navigator.serviceWorker.register(swUrl, { scope: '/' });
}

if (window.metaHtml) {
    const doc = new DOMParser().parseFromString(window.metaHtml, 'text/html');
    Object.values(doc?.head?.children || {}).forEach((node) =>
        document.head.appendChild(node));
}

// Code bellow enables the hot reloading of plugins
// Why? I have no idea. Further debugging needed.
// TODO: understand why this helps HMR
if (module.hot) {
    module.hot.accept();
}

/** @namespace Pwa/Render/HotApp */
export function HotApp() {
    return <App />;
}

// Attendere il caricamento di #root prima di eseguire il render
/** @namespace Pwa/Render/waitForRoot */
export const waitForRoot = async () => {
    const rootElement = await waitForElm('#root');
    const root = createRoot(rootElement);
    root.render(<HotApp />);
};

waitForRoot();

// Set the css app height variable
/** @namespace Pwa/Render/appHeight */
export const appHeight = () => {
    const doc = document.documentElement;
    doc.style.setProperty('--app-height', `${window.innerHeight}px`);
};
window.addEventListener('resize', appHeight);
appHeight();
