export const GTM_APPEND_DATA = 'GTM_APPEND_DATA';
export const CHANGE_SHIPPING_TYPE = 'CHANGE_SHIPPING_TYPE';
export const SAVE_ADDRESS_DATA = 'SAVE_ADDRESS_DATA';
export const CHANGE_STEP = 'CHANGE_STEP';
export const SET_LOADING = 'SET_LOADING';
export const LOAD_WAREHOUSES = 'LOAD_WAREHOUSES';
export const SELECTED_WAREHOUSE = 'SELECTED_WAREHOUSE';
export const SELECT_ADDRESS = 'SELECT_ADDRESS';
export const CHANGEADOQTY = 'CHANGEADOQTY';
export const ADOLOADING = 'ADOLOADING';
export const CART_QTY = 'CART_QTY';

/** @namespace Pwa/Store/AngoloDelleOccasioni/Action/appendData */
export const appendData = (data) => ({
    type: GTM_APPEND_DATA,
    data,
});

/** @namespace Pwa/Store/AngoloDelleOccasioni/Action/changeShippingType */
export const changeShippingType = (data) => ({
    type: CHANGE_SHIPPING_TYPE,
    data,
});
/** @namespace Pwa/Store/AngoloDelleOccasioni/Action/saveAddressData */
export const saveAddressData = (address) => ({
    type: SAVE_ADDRESS_DATA,
    address,
});

/** @namespace Pwa/Store/AngoloDelleOccasioni/Action/changeStep */
export const changeStep = (step) => ({
    type: CHANGE_STEP,
    step,
});

/** @namespace Pwa/Store/AngoloDelleOccasioni/Action/setLoading */
export const setLoading = (isLoading) => ({
    type: SET_LOADING,
    isLoading,
});

/** @namespace Pwa/Store/AngoloDelleOccasioni/Action/updateWarehouse */
export const updateWarehouse = (warehouses) => ({
    type: LOAD_WAREHOUSES,
    warehouses,
});

/** @namespace Pwa/Store/AngoloDelleOccasioni/Action/selectWarehouse */
export const selectWarehouse = (selectedWarehouse) => ({
    type: SELECTED_WAREHOUSE,
    selectedWarehouse,
});

/** @namespace Pwa/Store/AngoloDelleOccasioni/Action/changeQTY */
export const changeQTY = (thirdLevelQTY) => ({
    type: CHANGEADOQTY,
    thirdLevelQTY,
});

/** @namespace Pwa/Store/AngoloDelleOccasioni/Action/adoLoading */
export const adoLoading = (isADOLoaded) => ({
    type: ADOLOADING,
    isADOLoaded,
});

/** @namespace Pwa/Store/AngoloDelleOccasioni/Action/changeCartQTY */
export const changeCartQTY = (cartQTY) => ({
    type: CART_QTY,
    cartQTY,
});
