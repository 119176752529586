import {
    RESET_SUB_CATEGORY,
    SELECT_SUB_CATEGORY,
} from './SubCategoryFilterTags.action';

/** @namespace Pwa/Store/SubCategoryFilterTags/Reducer/getInitialState */
export const getInitialState = () => ({
    isShowCategoryFilters: false,
    selectedFilters: {},
    selectedAttributeSets: undefined,
    selectedAttributeSetFilter: {},
});

/** @namespace Pwa/Store/SubCategoryFilterTags/Reducer/SubCategoryFilterTagsReducer */
export const SubCategoryFilterTagsReducer = (
    state = getInitialState(),
    action
) => {
    const { type, categoryName } = action;
    switch (type) {
    case SELECT_SUB_CATEGORY:
        return {
            ...state,
            subCategorySelected: categoryName,
        };

    case RESET_SUB_CATEGORY:
        return {
            ...state,
            subCategorySelected: '',
        };

    default:
        return {
            ...state,
        };
    }
};

export default SubCategoryFilterTagsReducer;
