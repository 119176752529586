import { RETRIEVE_CATEGORY_BY_ID } from './CustomCategory.action';

/** @namespace Pwa/Store/CustomCategory/Reducer/getInitialState */
export const getInitialState = () => ({
    categoryRetrievedById: {},
});

/** @namespace Pwa/Store/CustomCategory/Reducer/CustomCategoryReducer */
export const CustomCategoryReducer = (
    state = getInitialState(),
    { type, categoryRetrievedById }
) => {
    switch (type) {
    case RETRIEVE_CATEGORY_BY_ID:
        return {
            ...state,
            categoryRetrievedById: { ...categoryRetrievedById },
        };

    default:
        return state;
    }
};

export default CustomCategoryReducer;
