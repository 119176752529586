import {
    ADOLOADING, CART_QTY, CHANGE_SHIPPING_TYPE, CHANGE_STEP, CHANGEADOQTY,
    LOAD_WAREHOUSES, SAVE_ADDRESS_DATA, SELECTED_WAREHOUSE,
    SET_LOADING,
} from './AngoloDelleOccasioni.action';

/** @namespace Pwa/Store/AngoloDelleOccasioni/Reducer/getInitialState */
export const getInitialState = () => ({
    data: {},
    mc_type: '0',
    step: '0',
    warehouses: [],
    isLoading: false,
    selectedWarehouse: null,
    thirdLevelQTY: undefined,
    isADOLoaded: false,
    cartQTY: [],
});

/** @namespace Pwa/Store/AngoloDelleOccasioni/Reducer/AngoloDelleOccasioniReducer */
export const AngoloDelleOccasioniReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case CHANGE_SHIPPING_TYPE:
        const { data } = action;

        return {
            ...state,
            mc_type: data,
        };
    case SAVE_ADDRESS_DATA:
        const { address } = action;

        return {
            ...state,
            data: { address },
        };
    case CHANGE_STEP:
        const { step } = action;

        return {
            ...state,
            step,
        };
    case LOAD_WAREHOUSES:
        const { warehouses } = action;

        return {
            ...state,
            warehouses,
        };
    case SET_LOADING:
        const { isLoading } = action;

        return {
            ...state,
            isLoading,
        };
    case SELECTED_WAREHOUSE:
        const { selectedWarehouse } = action;

        return {
            ...state,
            selectedWarehouse,
        };

    case CHANGEADOQTY:
        const { thirdLevelQTY } = action;

        return {
            ...state,
            thirdLevelQTY,
        };

    case ADOLOADING:
        const { isADOLoaded } = action;

        return {
            ...state,
            isADOLoaded,
        };

    case CART_QTY:
        const { cartQTY } = action;

        return {
            ...state,
            cartQTY,
        };

    default:
        return state;
    }
};

export default AngoloDelleOccasioniReducer;
