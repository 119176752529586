import { ProductListInfoActionType } from 'Store/ProductListInfo/ProductListInfo.type';
/** @namespace Pwa/Store/ProductListInfo/Reducer/reduceFilters */
export const reduceFilters = (filters = {}) => filters?.reduce((co, item) => {
    const {
        request_var: attribute_code,
        name: attribute_label,
        filter_items = {},
    } = item;

    const { attribute_values, attribute_options } = filter_items?.reduce((attribute, option) => {
        const { value_string } = option;
        const { attribute_values, attribute_options } = attribute;

        attribute_values.push(value_string);

        return {
            ...attribute,
            attribute_options: {
                ...attribute_options,
                [value_string]: option,
            },
        };
    }, { attribute_values: [], attribute_options: {} });

    return {
        ...co,
        [attribute_code]: {
            attribute_code,
            attribute_label,
            attribute_values,
            attribute_type: 'select',
            attribute_options,
        },
    };
}, {});

/** @namespace Pwa/Store/ProductListInfo/Reducer/getInitialState */
export const getInitialState = () => ({
    minPrice: 0,
    maxPrice: 0,
    sortFields: {},
    filters: {},
    isLoading: true,
});

/** @namespace Pwa/Store/ProductListInfo/Reducer/ProductListReducer */
export const ProductListReducer = (
    state = getInitialState(),
    action
) => {
    const { type } = action;
    switch (type) {
    case ProductListInfoActionType.UPDATE_PRODUCT_LIST_INFO: {
        // Allow to select multiple values, without reload the filters

        if (Object.keys(state.filters).length) {
            return {
                ...state,
                isLoading: false,
            };
        }

        const {
            selectedFilter,
            products: {
                filters: availableFilters = [],
                min_price,
                max_price,
                sort_fields: sortFields,
            } = {},
        } = action;

        return {
            ...state,
            filters: reduceFilters(availableFilters),
            sortFields,
            minPrice: Math.floor(min_price),
            maxPrice: Math.ceil(max_price),
            isLoading: false,
            selectedFilter,
        };
    }

    case ProductListInfoActionType.UPDATE_INFO_LOAD_STATUS: {
        const { isLoading } = action;

        return {
            ...state,
            isLoading,
        };
    }

    default:
        return state;
    }
};

export default ProductListReducer;
