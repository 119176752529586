import { createPortal } from 'react-dom';

import ClickOutside from 'Component/ClickOutside';
import { PopupComponent as SourcePopup } from 'SourceComponent/Popup/Popup.component';
import { ESCAPE_KEY } from 'SourceComponent/Popup/Popup.config';
import { toggleScroll } from 'Util/Browser';

import './Popup.override.style';

/** @namespace Pwa/Component/Popup/Component */
export class PopupComponent extends SourcePopup {
    render() {
        const {
            mix,
            areOtherOverlaysOpen,
            isSidePopup,
            isFullPopup,
            customClass,
        } = this.props;
        const isVisible = this.getIsVisible();

        return createPortal(
            <div
              ref={ this.overlayRef }
              block="Popup"
              mods={ {
                  isVisible,
                  isSidePopup,
                  isFullPopup,
                  isOverlayPopup: !isSidePopup,
                  isInstant: areOtherOverlaysOpen,
              } }
              mix={ { ...mix, mods: { ...mix.mods, isVisible } } }
              className={ customClass }
            >
                { customClass === 'newAddToCart' && (
                    <div className="Popup-LeftSide" onClick={ this.hidePopUp } />
                ) }
                { this.renderContent() }
            </div>,
            document.body
        );
    }

    hidePopUp = () => {
        const {
            hideActiveOverlay,
            goToPreviousNavigationState,
            onClose,
            scrollB,
        } = this.props;
        const isVisible = this.getIsVisible();

        if (isVisible) {
            const html = document.documentElement;

            if (scrollB) {
                html.style.scrollBehavior = 'auto';
            }

            this.unfreezeScroll();
            hideActiveOverlay();
            goToPreviousNavigationState();
            onClose();

            if (scrollB) {
                html.style.scrollBehavior = 'smooth';
            }
        }
    };

    freezeScroll() {
        const { isMobile } = this.props;
        this.YoffsetWhenScrollDisabled = window.pageYOffset || document.body.scrollTop;
        toggleScroll(false);

        if (!isMobile) {
            document.body.style.marginTop = `${-this
                .YoffsetWhenScrollDisabled}px`;
        }
    }

    // Same with click outside
    handleClickOutside() {
        const { isCloseOnOutsideClick } = this.props;

        if (!isCloseOnOutsideClick) {
            return;
        }

        this.hidePopupAndGoBack();
    }

    handleKeyDown(e) {
        switch (e.keyCode) {
        case ESCAPE_KEY:
            // this.hidePopupAndGoBack()
            break;
        default:
            break;
        }
    }

    renderCloseButton() {
        return (
            <button
              block="Popup"
              elem="CloseBtn"
              aria-label={ __('Close') }
              onClick={ this.hidePopupAndGoBack }
            />
        );
    }

    renderContent() {
        const { children, contentMix, customClass } = this.props;
        const isVisible = this.getIsVisible();

        if (!isVisible) {
            return null;
        }

        return (
            <ClickOutside onClick={ this.handleClickOutside }>
                <div block="Popup" elem="Wrapper" mix={ contentMix }>
                    <div block="Popup" elem="Body" className={ customClass }>
                        <header block="Popup" elem="Header">
                            { this.renderTitle() }
                            { this.renderCloseButton() }
                        </header>
                        { children }
                    </div>
                </div>
            </ClickOutside>
        );
    }
}

export default PopupComponent;
