import { DECREASE_LOADING, INCREASE_LOADING, RESET_LOADING } from './Configuratore.action';

/** @namespace Pwa/Store/Configuratore/Reducer/getInitialState */
export const getInitialState = () => ({
    isLoading: 0,
});

/** @namespace Pwa/Store/Configuratore/Reducer/ConfiguratoreReducer */
export const ConfiguratoreReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case INCREASE_LOADING:
        return {
            ...state,
            isLoading: state.isLoading + 1,
        };

    case DECREASE_LOADING:
        return {
            ...state,
            isLoading: state.isLoading - 1,
        };

    case RESET_LOADING:
        return {
            ...state,
            isLoading: 0,
        };

    default:
        return state;
    }
};

export default ConfiguratoreReducer;
