import AngoloDelleOccasioniReducer from 'Store/AngoloDelleOccasioni/AngoloDelleOccasioni.reducer';
import BlackFridayCounterCarouselReducer from 'Store/BlackFridayCounterCarousel/BlackFridayCounterCarousel.reducer';
import BlackFridayCounterProductReducer from 'Store/BlackFridayCounterProduct/BlackFridayCounterProduct.reducer';
import BlackFridayCounterPromoReducer from 'Store/BlackFridayCounterPromo/BlackFridayCounterPromo.reducer';
import BreadcrumbsReducer from 'Store/Breadcrumbs/Breadcrumbs.reducer';
import CartReducer from 'Store/Cart/Cart.reducer';
import CategoryReducer from 'Store/Category/Category.reducer';
import CategoryFiltersReducer from 'Store/CategoryFilters/CategoryFilters.reducer';
import CheckoutReducer from 'Store/Checkout/Checkout.reducer';
import CmsReducer from 'Store/Cms/Cms.reducer';
import ConfigReducer from 'Store/Config/Config.reducer';
import ConfiguratoreReducer from 'Store/Configuratore/Configuratore.reducer';
import ContactFormReducer from 'Store/ContactForm/ContactForm.reducer';
import CustomCategoryReducer from 'Store/CustomCategory/CustomCategory.reducer';
import FlashSaleProductReducer from 'Store/FlashSaleProduct/FlashSaleProduct.reducer';
import GTMReducer from 'Store/GTM/GTM.reducer';
import HomepageSliderReducer from 'Store/HomepageSlider/HomepageSlider.reducer';
import LinkedProductsReducer from 'Store/LinkedProducts/LinkedProducts.reducer';
import McAvailabilityReducer from 'Store/McAvailability/McAvailability.reducer';
import McAvailableVariants from 'Store/McAvailableVariants/McAvailableVariants.reducer';
import McMenuReducer from 'Store/McMenu/McMenu.reducer';
import McRealTimePromoReducer from 'Store/McRealTimePromo/McRealTimePromo.reducer';
import McTechSpecsReducer from 'Store/McTechSpecs/McTechSpecs.reducer';
import MetaReducer from 'Store/Meta/Meta.reducer';
import MyAccountReducer from 'Store/MyAccount/MyAccount.reducer';
import NavigationReducer from 'Store/Navigation/Navigation.reducer';
import NoMatchReducer from 'Store/NoMatch/NoMatch.reducer';
import NotificationReducer from 'Store/Notification/Notification.reducer';
import OfflineReducer from 'Store/Offline/Offline.reducer';
import OrderReducer from 'Store/Order/Order.reducer';
import OverlayReducer from 'Store/Overlay/Overlay.reducer';
import PopupReducer from 'Store/Popup/Popup.reducer';
import ProductReducer from 'Store/Product/Product.reducer';
import ProductCompareReducer from 'Store/ProductCompare/ProductCompare.reducer';
import ProductListReducer from 'Store/ProductList/ProductList.reducer';
import ProductListInfoReducer from 'Store/ProductListInfo/ProductListInfo.reducer';
import PromoReducer from 'Store/Promo/Promo.reducer';
import RecentlyViewedProductsReducer from 'Store/RecentlyViewedProducts/RecentlyViewedProducts.reducer';
import SearchBarReducer from 'Store/SearchBar/SearchBar.reducer';
import StoreLocatorReducer from 'Store/StoreLocator/StoreLocator.reducer';
import SubCategoryFilterTagsReducer from 'Store/SubCategoryFilterTags/SubCategoryFilterTags.reducer';
import SuccessPageLinksReducer from 'Store/SuccessPageLinks/SuccessPageLinks.reducer';
import TotalItemsSubCategoryFilteredReducer
    from 'Store/TotalItemsSubCategoryFiltered/TotalItemsSubCategoryFiltered.reducer';
import TrackingOrdiniReducer from 'Store/TrackingOrdini/TrackingOrdini.reducer';
import UrlRewritesReducer from 'Store/UrlRewrites/UrlRewrites.reducer';
import WarehouseReducer from 'Store/Warehouse/Warehouse.reducer';
import WishlistReducer from 'Store/Wishlist/Wishlist.reducer';

/** @namespace Pwa/Store/Index/getStaticReducers */
export const getStaticReducers = () => ({
    BlackFridayCounterPromoReducer,
    CategoryReducer,
    PromoReducer,
    ConfiguratoreReducer,
    // AvailabilityColorProductListReducer,
    NotificationReducer,
    BreadcrumbsReducer,
    ProductReducer,
    ProductListReducer,
    ProductListInfoReducer,
    CartReducer,
    OrderReducer,
    WishlistReducer,
    NoMatchReducer,
    SearchBarReducer,
    MyAccountReducer,
    NavigationReducer,
    OverlayReducer,
    OfflineReducer,
    PopupReducer,
    UrlRewritesReducer,
    ConfigReducer,
    MetaReducer,
    LinkedProductsReducer,
    CheckoutReducer,
    McRealTimePromoReducer,
    ContactFormReducer,
    BlackFridayCounterProductReducer,
    FlashSaleProductReducer,
    RecentlyViewedProductsReducer,
    ProductCompareReducer,
    StoreLocatorReducer,
    SuccessPageLinksReducer,
    TrackingOrdiniReducer,
    McAvailabilityReducer,
    McAvailableVariants,
    McTechSpecsReducer,
    HomepageSliderReducer,
    BlackFridayCounterCarouselReducer,
    McMenuReducer,
    CategoryFiltersReducer,
    GTMReducer,
    WarehouseReducer,
    CustomCategoryReducer,
    SubCategoryFilterTagsReducer,
    TotalItemsSubCategoryFilteredReducer,
    AngoloDelleOccasioniReducer,
    CmsReducer,
});

export default function injectStaticReducers(store) {
    // Inject all the static reducers into the store
    Object.entries(getStaticReducers()).forEach(
        ([name, reducer]) => store.injectReducer(name, reducer)
    );

    return store;
}
