import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './CountdownTimer.style';

/** @namespace Pwa/Component/CountdownTimer/Component */
export class CountdownTimerComponent extends PureComponent {
    static propTypes = {
        // TODO: implement prop-types
        type: PropTypes.PropTypes.oneOf([
            'reel',
            'slider',
        ]).isRequired,
        isFull: PropTypes.bool,
        isWithoutDays: PropTypes.bool,
        mods: PropTypes.string.isRequired,
        countdown: PropTypes.shape({
            days: PropTypes.number,
            hours: PropTypes.number,
            minutes: PropTypes.number,
            seconds: PropTypes.number,
        }).isRequired,
    };

    static defaultProps = {
        isFull: false,
        isWithoutDays: false,
    };

    render() {
        const {
            countdown: {
                days, hours, minutes, seconds,
            },
            mods,
            type,
            isFull,
            isWithoutDays,
        } = this.props;

        return (
            <div block="CountdownTimer" mods={ { mods, type } }>
                { !isWithoutDays && (
                <div block="CountdownTimer" elem="Item">
                    { days }
                    { isFull && ('d') }
                </div>
                ) }
                <div block="CountdownTimer" elem="Item">
                    { hours }
                    { isFull && ('h') }
                </div>
                <div block="CountdownTimer" elem="Item">
                    { minutes }
                    { isFull && ('m') }
                </div>
                <div block="CountdownTimer" elem="Item">
                    { seconds }
                    { isFull && ('s') }
                </div>
            </div>
        );
    }
}

export default CountdownTimerComponent;
