import { TRACKING_ORDINI_ACTION_TYPE } from './TrackingOrdini.action';

/** @namespace Pwa/Store/TrackingOrdini/Reducer/getInitialState */
export const getInitialState = () => ({
    trackingOrdini: {},
});

/** @namespace Pwa/Store/TrackingOrdini/Reducer/TrackingOrdiniReducer */
export const TrackingOrdiniReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case TRACKING_ORDINI_ACTION_TYPE:
        const { payload } = action;

        return {
            ...state,
            trackingOrdini: payload,
        };

    default:
        return state;
    }
};

export default TrackingOrdiniReducer;
