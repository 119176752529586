export const RETRIEVE_CATEGORY_BY_ID = 'RETRIEVE_CATEGORY_BY_ID';

/**
 * Update Specific Category
 * @return {void}
 * @param category
 * @namespace Pwa/Store/CustomCategory/Action/updateCurrentCategory */
export const updateCurrentCategory = (categoryRetrievedById) => ({
    type: RETRIEVE_CATEGORY_BY_ID,
    categoryRetrievedById,
});
