import { connect } from 'react-redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    MetaContainer as SourceMetaContainer,
} from 'SourceComponent/Meta/Meta.container';

/** @namespace Pwa/Component/Meta/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    // TODO extend mapStateToProps
});

/** @namespace Pwa/Component/Meta/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    // TODO extend mapDispatchToProps
});

/** @namespace Pwa/Component/Meta/Container */
export class MetaContainer extends SourceMetaContainer {
    injectHeadTag(tagName) {
        const tag = document.createElement(tagName);
        document.getElementsByTagName('head')[0].appendChild(tag);

        return tag;
    }

    updateOrInjectHeadTag(selector, tagName, attributes, value = null) {
        const tag = document.querySelector(selector) || this.injectHeadTag(tagName);

        // eslint-disable-next-line no-restricted-syntax
        for (const name of Object.keys(attributes)) {
            tag.setAttribute(name, attributes[name]);
        }

        if (value !== null) {
            tag.textContent = value;
        }
    }

    replaceOriginalCanonicalWithCustomCanonical(origCanonical) {
        const customCanonicalsMapping = {
            'dormitorios-juveniles/sillas-giratorias': 'complementos/sillas-giratorias',
            'cocinas/electrodomesticos': 'electrodomesticos',
            'cocinas/electrodomesticos/placas-cocinas-gas': 'electrodomesticos/placas-cocinas-gas',
            'cocinas/electrodomesticos/hornos': 'electrodomesticos/hornos',
            'cocinas/electrodomesticos/frigorificos': 'electrodomesticos/frigorificos',
            'cocinas/electrodomesticos/lavavajillas-empotradas': 'electrodomesticos/lavavajillas-empotradas',
            'cocinas/electrodomesticos/hornos-de-microondas': 'electrodomesticos/hornos-de-microondas',
            'cocinas/electrodomesticos/lavadora': 'electrodomesticos/lavadora',
            'cocinas/electrodomesticos/frigorificos-de-libre-instalacion':
                'electrodomesticos/frigorificos-de-libre-instalacion',
            'camas/canapes': 'somieres-y-colchones/canapes',
            'dormitorios/cabezales': 'camas/cabezales',
        };
        const DELETE_COUNT = 3;

        const origCanonicalArray = origCanonical.split('/');
        origCanonicalArray.splice(0, DELETE_COUNT);
        const origCanonicalWithoutBaseUrl = origCanonicalArray.join('/').replace('.html', '');

        if (Object.prototype.hasOwnProperty.call(customCanonicalsMapping, origCanonicalWithoutBaseUrl)) {
            return origCanonical.replace(
                origCanonicalWithoutBaseUrl,
                customCanonicalsMapping[origCanonicalWithoutBaseUrl]
            );
        }

        return origCanonical;
    }

    componentDidUpdate(_prevProps, _prevState, _snapshot) {
        const {
            title, canonical_url, description, robots, status_code,
            // keywords,
            default_title,
        } = this.props;

        if (!default_title || canonical_url.match(/undefined$/)) {
            return;
        }

        // Force update SSR meta tags
        if (title) {
            this.updateOrInjectHeadTag('title', 'title', {}, title);
            this.updateOrInjectHeadTag("meta[name='title']", 'meta', {
                name: 'title',
                content: title,
            });
        }

        if (description) {
            this.updateOrInjectHeadTag("meta[name='description']", 'meta', {
                name: 'description',
                content: description,
            });
        }

        if (status_code) {
            this.updateOrInjectHeadTag("meta[name='render:status_code']", 'meta', {
                name: 'render:status_code',
                content: status_code,
            });
        }

        // if (keywords) {
        //     this.updateOrInjectHeadTag("meta[name='keywords']", 'meta', {
        //         name: 'keywords',
        //         content: keywords
        //     });
        // }

        if (robots) {
            this.updateOrInjectHeadTag("meta[name='robots']", 'meta', {
                name: 'robots',
                content: robots,
            });
        }

        if (canonical_url) {
            this.updateOrInjectHeadTag("link[rel='canonical']", 'link', {
                rel: 'canonical',
                href: this.replaceOriginalCanonicalWithCustomCanonical(canonical_url.replace(/\/$/, '')),
            });
        } else {
            let cleanUrl = window.location.href.toLowerCase().includes('?page=') ? window.location.href : window.location.origin + window.location.pathname;
            cleanUrl = cleanUrl.replace(/\/$/, '');

            this.updateOrInjectHeadTag("link[rel='canonical']", 'link', {
                rel: 'canonical',
                href: this.replaceOriginalCanonicalWithCustomCanonical(cleanUrl),
            });
        }
    }

    _getMetadata() {
        const meta = {
            title: this._getTitle(),
            description: this._getDescription(),
            robots: this._getRobots(),
            'render:status_code': this._getStatusCode(),
        };

        return this._generateMetaFromMetadata(meta);
    }

    render() {
        return null;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MetaContainer);
