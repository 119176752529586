import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { countdown } from 'Util/Countdown';

import CountdownTimer from './CountdownTimer.component';

/** @namespace Pwa/Component/CountdownTimer/Container */
export class CountdownTimerContainer extends PureComponent {
    static propTypes = {
        type: PropTypes.PropTypes.oneOf([
            'reel',
            'slider',
        ]).isRequired,
        isFull: PropTypes.bool,
        isWithoutDays: PropTypes.bool,
        date: PropTypes.string.isRequired,
        mods: PropTypes.string.isRequired,
    };

    static defaultProps = {
        isFull: false,
        isWithoutDays: false,
    };

    state = {
        countdown: {
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
        },
    };

    containerFunctions = {
        // getData: this.getData.bind(this)
    };

    componentDidMount() {
        const { date } = this.props;
        this.carouselInterval = countdown(date, (countdown) => {
            const {
                days, hours, minutes, seconds,
            } = countdown;
            const { countdown: currentCountdown } = this.state;

            if (
                currentCountdown.days !== days
                || currentCountdown.hours !== hours
                || currentCountdown.minutes !== minutes
                || currentCountdown.seconds !== seconds
            ) {
                this.setState({ countdown });
            }
        });
    }

    componentWillUnmount() {
        clearTimeout(this.carouselInterval);
    }

    containerProps = () => {
        const { countdown } = this.state;
        const {
            mods, type, isFull, isWithoutDays,
        } = this.props;

        return {
            countdown,
            mods,
            type,
            isFull,
            isWithoutDays,
        };
    };

    render() {
        return (
            <CountdownTimer
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default CountdownTimerContainer;
