import PropTypes from 'prop-types';

export const DeviceType = PropTypes.shape({
    isMobile: PropTypes.bool.isRequired,
    android: PropTypes.bool.isRequired,
    ios: PropTypes.bool.isRequired,
    blackberry: PropTypes.bool.isRequired,
    opera: PropTypes.bool.isRequired,
    safari: PropTypes.bool.isRequired,
    windows: PropTypes.bool.isRequired,
    standaloneMode: PropTypes.bool,
});
