// eslint-disable-next-line max-len
import { BLACK_FRIDAY_COUNTER_PRODUCT_ACTION_TYPE } from 'Store/BlackFridayCounterProduct/BlackFridayCounterProduct.action';

/** @namespace Pwa/Store/BlackFridayCounterProduct/Reducer/getInitialState */
export const getInitialState = () => ({
    blackFridayCounterProducts: [],
});

/** @namespace Pwa/Store/BlackFridayCounterProduct/Reducer/BlackFridayCounterProductReducer */
export const BlackFridayCounterProductReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case BLACK_FRIDAY_COUNTER_PRODUCT_ACTION_TYPE:
        const { payload } = action;

        return {
            ...state,
            blackFridayCounterProducts: payload,
        };

    default:
        return state;
    }
};

export default BlackFridayCounterProductReducer;
