import {
    getInitialState as sourceGetInitialState,
    ProductCompareReducer as SourceProductCompareReducer,
} from 'SourceStore/ProductCompare/ProductCompare.reducer';

// TODO: implement getInitialState
export const getInitialState = sourceGetInitialState;

// TODO: implement ProductCompareReducer
export const ProductCompareReducer = SourceProductCompareReducer;

export default ProductCompareReducer;
