import { lazy } from 'react';
import { Route } from 'react-router-dom';

import Gtm from 'Component/Gtm';
import PromoPopup from 'Component/PromoPopup';
import UrlRewrites from 'Route/UrlRewrites';
import { RouterComponent as SourceRouter, withStoreRegex } from 'SourceComponent/Router/Router.component';
import { RouterItemType } from 'SourceComponent/Router/Router.config';
import history from 'Util/History';

import './Router.override.style';

export const CartPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "cart" */ 'Route/CartPage'));
export const Checkout = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "checkout" */ 'Route/Checkout'));
export const CmsPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "cms" */ 'Route/CmsPage'));
export const HomePage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "cms" */ 'Route/HomePage'));
export const MyAccount = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "account" */ 'Route/MyAccount'));
export const PasswordChangePage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "misc" */ 'Route/PasswordChangePage'));
export const SearchPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "category" */ 'Route/SearchPage'));
export const ConfirmAccountPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "cms" */ 'Route/ConfirmAccountPage'));
export const MenuPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "cms" */ 'Route/MenuPage'));
export const WishlistShared = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "misc" */ 'Route/WishlistSharedPage'));
export const ContactPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "contact" */ 'Route/ContactPage'));
export const ProductComparePage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "compare" */ 'Route/ProductComparePage'));
export const CreateAccountPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "account" */ 'Route/CreateAccount'));
export const LoginAccountPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "account" */ 'Route/LoginAccount'));
export const ForgotPasswordPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "account" */ 'Route/ForgotPassword'));
export const AngoloDelleOccasioni = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "account" */ 'Route/AngoloDelleOccasioni'));
export const ThankYouPageFindo = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "account" */ 'Route/ThankYouPageFindo'));
export const Collezioni = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "account" */ 'Route/Collezioni'));
export const CollezioniDettaglio = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "account" */ 'Route/CollezioniDettaglio'));
export const SuccessPageDolceCasa = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "account" */ 'Route/SuccessPageDolceCasa'));

export const BlackFridayCategory = lazy(
    () => import(/* webpackMode: "lazy",
    webpackChunkName: "BlackFridayCategory" */ 'Route/BlackFridayCategory'
    )
);

export const PuntiVendita = lazy(
    () => import(/* webpackMode: "lazy",
    webpackChunkName: "PuntiVenditaComponent" */ 'Route/PuntiVendita'
    )
);
// export const PuntiVenditaRegione = lazy(
//     () => import(/* webpackMode: "lazy",
//     webpackChunkName: "PuntiVenditaRegioneComponent" */ 'Route/PuntiVenditaRegione'
//     )
// );
// export const PuntiVenditaDettaglio = lazy(
//     () => import(/* webpackMode: "lazy",
//     webpackChunkName: "PuntiVenditaDettaglioComponent" */ 'Route/PuntiVenditaDettaglio'
//     )
// );

// export const Styleguide = lazy(
//     () => import(/* webpackMode: "lazy", webpackChunkName: "Styleguide" */ 'Route/Styleguide')
// );

export const SecondLevel = lazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "SecondLevel" */ 'Route/SecondLevel')
);

export const DolceCasa = lazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "DolceCasa" */ 'Route/DolceCasa')
);

export const SuccessPage = lazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "SuccessPage" */ 'Route/SuccessPage')
);

export const FailPage = lazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "FailPage" */ 'Route/FailPage')
);

export const FraudPage = lazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "FraudPage" */ 'Route/FraudPage')
);

export const AppuntamentoVenditoreCancel = lazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "FraudPage" */ 'Route/AppuntamentoVenditoreCancel')
);

export const AppuntamentoVideoconferenzaCancel = lazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "FraudPage" */ 'Route/AppuntamentoVideoconferenzaCancel')
);

export const ServizioClienti = lazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "ServizioClienti" */ 'Route/ServizioClienti')
);

export const ComeAcquistare = lazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "ServizioClienti" */ 'Route/ServizioClienti/ComeAcquistare')
);

export const ConsegnaMontaggio = lazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "ServizioClienti" */ 'Route/ServizioClienti/ConsegnaMontaggio')
);

export const ModalitaPagamento = lazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "ServizioClienti" */ 'Route/ServizioClienti/ModalitaPagamento')
);

export const Servizi = lazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "ServizioClienti" */ 'Route/ServizioClienti/Servizi')
);

export const Garanzie = lazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "ServizioClienti" */ 'Route/ServizioClienti/Garanzie')
);

export const FilosofiaAziendale = lazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "FilosofiaAziendale" */ 'Route/Azienda/FilosofiaAziendale')
);

export const NostriValori = lazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "NostriValori" */ 'Route/Azienda/NostriValori')
);

export const Storia = lazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "Storia" */ 'Route/Azienda/Storia')
);

export const Presente = lazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "Presente" */ 'Route/Azienda/Presente')
);

export const ValoreAggiunto = lazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "ValoreAggiunto" */ 'Route/Azienda/ValoreAggiunto')
);

export const InnovazioneRicerca = lazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "InnovazioneRicerca" */ 'Route/Azienda/InnovazioneRicerca')
);

export const Jingles = lazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "Jingles" */ 'Route/Azienda/Jingles')
);

export const LavoraConNoi = lazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "LavoraConNoi" */ 'Route/Azienda/LavoraConNoi')
);

export const TrackingOrderDisplay = lazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "TrackingOrderDisplay" */ 'Route/TrackingOrderDisplay')
);

export const NoFollow = lazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "NoFollow" */ 'Route/NoFollow')
);

export const StatoTuoOrdine = lazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "StatoTuoOrdine" */ 'Route/StatoTuoOrdine')
);

export const SurveyAcquistoEcommerce = lazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "SurveyAcquistoEcommerce" */ 'Route/SurveyAcquistoEcommerce')
);

export const Catalogo = lazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "Catalogo" */ 'Route/Catalogo')
);

export const Sicurezza = lazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "Sicurezza" */ 'Route/Sicurezza')
);

export const Sitemap = lazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "Sitemap" */ 'Route/Sitemap')
);

export const BlackFriday = lazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "BlackFriday" */ 'Route/BlackFriday')
);

export const TrasportoMontaggio = lazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "TrasportoMontaggio" */ 'Route/TrasportoMontaggio')
);

export const PromozioneCameretteCupon = lazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "PromozioneCameretteCupon" */ 'Route/PromozioneCameretteCupon')
);

export const PromozioneCamereCupon = lazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "PromozioneCamereCupon" */ 'Route/PromozioneCamereCupon')
);

export const ConcorsoCatanzaro = lazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "ConcorsoCatanzaro" */ 'Route/ConcorsoCatanzaro')
);

export const McDay2022 = lazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "McDay2022" */ 'Route/McDay2022')
);

export const SuperSaldiArredamento = lazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "SuperSaldiArredamento" */ 'Route/SuperSaldiArredamento')
);

export const McDay2023 = lazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "McDay2023" */ 'Route/McDay2023')
);

export const McDay2024 = lazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "McDay2024" */ 'Route/McDay2024')
);

export const Whistleblowing = lazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "Whistleblowing" */ 'Route/Whistleblowing')
);

// /**
//  * per gestire i redirect da /appuntamento-venditore a /appuntamento-venditore/index/index che prima
//  * venivano fatti in automatico da Magento
//  */
export const AppuntamentoVenditoreRedirect = lazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "FraudPage" */ 'Route/AppuntamentoVenditoreRedirect')
);

export const AppuntamentoVenditoreSuccess = lazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "FraudPage" */ 'Route/AppuntamentoVenditoreSuccess')
);

export const ConfiguratoreCucine = lazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "FraudPage" */ 'Route/ConfiguratoreCucine')
);

export const ConfiguratoreDivani = lazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "FraudPage" */ 'Route/ConfiguratoreDivani')
);

export const ConfiguratoreMaterassi = lazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "FraudPage" */ 'Route/ConfiguratoreMaterassi')
);

export const LandingInterniArmadi = lazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "FraudPage" */ 'Route/LandingInterniArmadi')
);

export const LandingPageSedie = lazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "FraudPage" */ 'Route/LandingPageSedie')
);

// eslint-disable-next-line @scandipwa/scandipwa-guidelines/create-config-files
export const SCANDI_CHECKOUT_ROUTE_POSITION = 55;
// eslint-disable-next-line @scandipwa/scandipwa-guidelines/create-config-files
export const SCANDI_NAVIGATION_TABS_POSITION = 25;
// eslint-disable-next-line @scandipwa/scandipwa-guidelines/create-config-files
export const SCANDI_BREADCRUMBS_POSITION = 30;

export const BEFORE_ITEMS_TYPES_TO_EXCLUDE = [SCANDI_NAVIGATION_TABS_POSITION, SCANDI_BREADCRUMBS_POSITION];

/** @namespace Pwa/Component/Router/Component */
export class RouterComponent extends SourceRouter {
    [RouterItemType.BEFORE_ITEMS_TYPE] = [
        ...Array.from(this[RouterItemType.BEFORE_ITEMS_TYPE].filter((item) => !BEFORE_ITEMS_TYPES_TO_EXCLUDE.includes(item.position))),
        {
            component: <Route render={ (props) => <PromoPopup routerProps={ props } /> } />,
            position: 0,
        },
    ];

    [RouterItemType.SWITCH_ITEMS_TYPE] = [ // aggiungo la rotta a quelle giù presenti
        // rimuovo la rotta del checkout standard di scandi
        // ...this[RouterItemType.SWITCH_ITEMS_TYPE].filter((item) => item.position !== SCANDI_CHECKOUT_ROUTE_POSITION),
        {
            component: <Route
              path={ withStoreRegex('/') }
              exact
              render={ (props) => (
                    <Gtm pageType="home">
                        <HomePage { ...props } />
                    </Gtm>
              ) }
            />,
            position: 10,
        },
        {
            component: <Route
              path={ withStoreRegex('/search/:query/') }
              render={ (props) => (
                    <Gtm pageType="search">
                        <SearchPage { ...props } />
                    </Gtm>
              ) }
            />,
            position: 25,
        },
        {
            component: <Route
              path={ withStoreRegex('/page') }
              render={ (props) => (
                    <Gtm pageType="other">
                        <CmsPage { ...props } />
                    </Gtm>
              ) }
            />,
            position: 40,
        },
        {
            component: <Route
              path={ withStoreRegex('/cart') }
              exact
              render={ (props) => (
                    <Gtm pageType="cart">
                        <CartPage { ...props } />
                    </Gtm>
              ) }
            />,
            position: 50,
        },
        {
            component: <Route
              path={ withStoreRegex('/:account*/createPassword/') }
              render={ (props) => (
                    <Gtm pageType="account">
                        <PasswordChangePage { ...props } />
                    </Gtm>
              ) }
            />,
            position: 60,
        },
        {
            component: <Route
              path={ withStoreRegex('/:account*/create/') }
              render={ (props) => (
                    <Gtm pageType="account">
                        <CreateAccountPage { ...props } />
                    </Gtm>
              ) }
            />,
            position: 61,
        },
        {
            component: <Route
              path={ withStoreRegex('/:account*/login/') }
              render={ (props) => (
                    <Gtm pageType="account">
                        <LoginAccountPage { ...props } />
                    </Gtm>
              ) }
            />,
            position: 62,
        },
        {
            component: <Route
              path={ withStoreRegex('/:account*/forgotpassword/') }
              render={ (props) => (
                    <Gtm pageType="account">
                        <ForgotPasswordPage { ...props } />
                    </Gtm>
              ) }
            />,
            position: 63,
        },
        {
            component: <Route
              path={ withStoreRegex('/:account*/confirm') }
              render={ (props) => (
                    <Gtm pageType="account">
                        <ConfirmAccountPage { ...props } />
                    </Gtm>
              ) }
            />,
            position: 65,
        },
        {
            component: <Route
              path={ withStoreRegex('/my-account/:tab?') }
              render={ (props) => (
                    <Gtm pageType="account">
                        <MyAccount { ...props } />
                    </Gtm>
              ) }
            />,
            position: 70,
        },
        {
            component: <Route
              path={ withStoreRegex('/forgot-password') }
              render={ (props) => (
                    <Gtm pageType="account">
                        <MyAccount { ...props } />
                    </Gtm>
              ) }
            />,
            position: 71,
        },
        {
            component: <Route
              path={ withStoreRegex('/menu') }
              render={ (props) => (
                    <Gtm pageType="other">
                        <MenuPage { ...props } />
                    </Gtm>
              ) }
            />,
            position: 80,
        },
        {
            component: <Route
              path={ withStoreRegex('/wishlist/shared/:code') }
              render={ (props) => (
                    <Gtm pageType="account">
                        <WishlistShared { ...props } />
                    </Gtm>
              ) }
            />,
            position: 81,
        },
        {
            component: <Route
              path={ withStoreRegex('/contact') }
              render={ (props) => (
                    <Gtm pageType="contatti">
                        <ContactPage { ...props } />
                    </Gtm>
              ) }
            />,
            position: 82,
        },
        {
            component: <Route
              path={ withStoreRegex('/compare') }
              render={ (props) => (
                    <Gtm pageType="other">
                        <ProductComparePage { ...props } />
                    </Gtm>
              ) }
            />,
            position: 83,
        },
        {
            component: <Route render={ ({ match }) => <UrlRewrites match={ match } location={ location } /> } />,
            position: 1000,
        },
        // {
        //     component: <Route
        //         path={withStoreRegex('/punti-vendita/shop/area/regione/:region')}
        //         render={(props) => (
        //             <Gtm pageType="store">
        //                 <PuntiVenditaRegione {...props} />
        //             </Gtm>
        //         )}
        //     />,
        //     position: 101
        // },
        // {
        //     component: <Route
        //         path={withStoreRegex('/punti-vendita/shop/index/negozio/:shop')}
        //         exact
        //         render={(props) => (
        //             <Gtm pageType="store">
        //                 <PuntiVenditaDettaglio {...props} />
        //             </Gtm>
        //         )}
        //     />,
        //     position: 102
        // },

        // {
        //     component: <Route path="/styleguide" exact render={() => <Styleguide />} />,
        //     position: 110
        // },
        {
            component: <Route
              path={ [withStoreRegex('/punti-vendita'), withStoreRegex('/tiendas')] }
              exact
              render={ (props) => (
                    <Gtm pageType="store">
                        <PuntiVendita { ...props } />
                    </Gtm>
              ) }
            />,
            position: 103,
        },
        {
            component: <Route
              path={ withStoreRegex('/come-acquistare/progetto-dolce-casa') }
              exact
              render={ (props) => (
                    <Gtm pageType="appuntamento">
                        <DolceCasa { ...props } />
                    </Gtm>
              ) }
            />,
            position: 120,
        },
        {
            component: <Route
              path="/:slug-:sku([^\s]{4})-details"
              exact
              render={ (props) => {
                  const productProps = {
                      ...props,
                      productSKU: props.match.params.sku.toUpperCase(),
                  };

                  return (
                        <Gtm pageType="product">
                            <SecondLevel { ...productProps } />
                        </Gtm>
                  );
              } }
            />,
            position: 21,
        },
        {
            component: <Route
              path={ withStoreRegex('/mc_payment/result/success') }
              exact
              render={ (props) => (
                    <Gtm pageType="checkout">
                        <SuccessPage { ...props } />
                    </Gtm>
              ) }
            />,
            position: 130,
        },
        {
            component: <Route
              path={ withStoreRegex('/mc_payment/result/fail') }
              exact
              render={ (props) => (
                    <Gtm pageType="checkout">
                        <FailPage { ...props } />
                    </Gtm>
              ) }
            />,
            position: 140,
        },
        {
            component: <Route
              path={ withStoreRegex('/mc_payment/result/fraud') }
              exact
              render={ (props) => (
                    <Gtm pageType="checkout">
                        <FraudPage { ...props } />
                    </Gtm>
              ) }
            />,
            position: 150,
        },
        {
            component: <Route
              path={ ['/offerte-black-friday', '/ofertas-black-friday'] }
              exact
              render={ (props) => (
                    <Gtm pageType="offerte">
                        <BlackFridayCategory { ...props } />
                    </Gtm>
              ) }
            />,
            position: 155,
        },
        {
            component: <Route
              exact
              path="/appuntamento-venditore"
              render={ (props) => (
                    <Gtm pageType="appuntamento">
                        <AppuntamentoVenditoreRedirect { ...props } />
                    </Gtm>
              ) }
            />,
            position: 160,
        },
        {
            component: <Route
              exact
              path="/prenotazione-videoconferenza"
              render={ (props) => (
                    <Gtm pageType="appuntamento">
                        <AppuntamentoVenditoreRedirect { ...props } />
                    </Gtm>
              ) }
            />,
            position: 170,
        },
        {
            component: <Route
              exact
              path="/cita-vendedor"
              render={ (props) => (
                    <Gtm pageType="appuntamento">
                        <AppuntamentoVenditoreRedirect { ...props } />
                    </Gtm>
              ) }
            />,
            position: 180,
        },
        {
            component: <Route
              exact
              path="/reserva-videoconferencia"
              render={ (props) => (
                    <Gtm pageType="appuntamento">
                        <AppuntamentoVenditoreRedirect { ...props } />
                    </Gtm>
              ) }
            />,
            position: 190,
        },
        {
            component: <Route
              exact
              path="/appuntamento-venditore/success/index/h/:hash/i/:id"
              render={ (props) => (
                    <Gtm pageType="appuntamento">
                        <AppuntamentoVenditoreSuccess { ...props } />
                    </Gtm>
              ) }
            />,
            position: 200,
        },
        {
            component: <Route
              exact
              path="/prenotazione-videoconferenza/success/index/h/:hash/i/:id"
              render={ (props) => (
                    <Gtm pageType="appuntamento">
                        <AppuntamentoVenditoreSuccess { ...props } />
                    </Gtm>
              ) }
            />,
            position: 210,
        },
        {
            component: <Route
              exact
              path="/appuntamento-venditore/cancel/index/h/:hash/i/:id"
              render={ (props) => (
                    <Gtm pageType="appuntamento">
                        <AppuntamentoVenditoreCancel { ...props } />
                    </Gtm>
              ) }
            />,
            position: 220,
        },
        {
            component: <Route
              exact
              path="/prenotazione-videoconferenza/cancel/index/h/:hash/i/:id"
              render={ (props) => (
                    <Gtm pageType="appuntamento">
                        <AppuntamentoVideoconferenzaCancel { ...props } />
                    </Gtm>
              ) }
            />,
            position: 221,
        },
        {
            component: <Route
              path={ ['/servizio-clienti', '/atencion-al-cliente'] }
              exact
              render={ (props) => (
                    <Gtm pageType="servizi">
                        <ServizioClienti { ...props } />
                    </Gtm>
              ) }
            />,
            position: 500,
        },
        {
            component: <Route
              path={ ['/come-acquistare/:page*', '/como-comprar/:page*'] }
              exact
              render={ (props) => (
                    <Gtm pageType="servizi">
                        <ComeAcquistare { ...props } />
                    </Gtm>
              ) }
            />,
            position: 501,
        },
        {
            component: <Route
              path={ ['/consegna-montaggio/:page*', '/entrega-y-montaje/:page*'] }
              exact
              render={ (props) => (
                    <Gtm pageType="servizi">
                        <ConsegnaMontaggio { ...props } />
                    </Gtm>
              ) }
            />,
            position: 502,
        },
        {
            component: <Route
              path={ ['/modalita-pagamento/:page*', '/pagos/:page*'] }
              exact
              render={ (props) => (
                    <Gtm pageType="servizi">
                        <ModalitaPagamento { ...props } />
                    </Gtm>
              ) }
            />,
            position: 503,
        },
        {
            component: <Route
              path={ ['/servizi/:page*', '/servicios/:page*'] }
              exact
              render={ (props) => (
                    <Gtm pageType="servizi">
                        <Servizi { ...props } />
                    </Gtm>
              ) }
            />,
            position: 504,
        },
        {
            component: <Route
              path={ ['/garanzie/:page*', '/garantias/:page*'] }
              exact
              render={ (props) => (
                    <Gtm pageType="other">
                        <Garanzie { ...props } />
                    </Gtm>
              ) }
            />,
            position: 505,
        },
        {
            component: <Route
              path={ ['/filosofia/filosofia-aziendale', '/filosofia/filosofia-empresarial'] }
              exact
              render={ (props) => (
                    <Gtm pageType="other">
                        <FilosofiaAziendale { ...props } />
                    </Gtm>
              ) }
            />,
            position: 506,
        },
        {
            component: <Route
              path={ ['/valori/i-nostri-valori', '/valores/nuestros-valores'] }
              exact
              render={ (props) => (
                    <Gtm pageType="other">
                        <NostriValori { ...props } />
                    </Gtm>
              ) }
            />,
            position: 507,
        },
        {
            component: <Route
              path={ ['/storia/la-storia', '/historia/historia'] }
              exact
              render={ (props) => (
                    <Gtm pageType="other">
                        <Storia { ...props } />
                    </Gtm>
              ) }
            />,
            position: 508,
        },
        {
            component: <Route
              path={ ['/presente/il-presente', '/presente/el-presente'] }
              exact
              render={ (props) => (
                    <Gtm pageType="other">
                        <Presente { ...props } />
                    </Gtm>
              ) }
            />,
            position: 509,
        },
        {
            component: <Route
              path={ ['/valore-aggiunto/il-valore-aggiunto', '/valor-adjunto/el-valor-adjunto'] }
              exact
              render={ (props) => (
                    <Gtm pageType="other">
                        <ValoreAggiunto { ...props } />
                    </Gtm>
              ) }
            />,
            position: 510,
        },
        {
            component: <Route
              path="/innovazione/innovazione-e-ricerca"
              exact
              render={ (props) => (
                    <Gtm pageType="other">
                        <InnovazioneRicerca { ...props } />
                    </Gtm>
              ) }
            />,
            position: 511,
        },
        {
            component: <Route
              path="/jingles/i-jingle"
              exact
              render={ (props) => (
                    <Gtm pageType="other">
                        <Jingles { ...props } />
                    </Gtm>
              ) }
            />,
            position: 512,
        },
        {
            component: <Route
              path={ ['/lavora', '/trabajo'] }
              exact
              render={ (props) => (
                    <Gtm pageType="other">
                        <LavoraConNoi { ...props } />
                    </Gtm>
              ) }
            />,
            position: 513,
        },
        {
            component: <Route
              exact
              path="/tracking/index/display/tid/:tid"
              render={ (props) => (
                    <Gtm pageType="account">
                        <TrackingOrderDisplay { ...props } />
                    </Gtm>
              ) }
            />,
            position: 300,
        },
        {
            component: <Route
              path="/catalogo"
              exact
              render={ (props) => (
                    <Gtm pageType="catalogo">
                        <Catalogo { ...props } />
                    </Gtm>
              ) }
            />,
            position: 514,
        },
        {
            component: <Route
              path={ ['/sicurezza', '/recomencemos'] }
              exact
              render={ (props) => (
                    <Gtm pageType="other">
                        <Sicurezza { ...props } />
                    </Gtm>
              ) }
            />,
            position: 515,
        },
        {
            component: <Route
              path={ ['/mondo-convenienza-day-offerta', '/aniversario', '/sorteo-aniversario', '/condiciones-generales-cupon-nos-importas-descuento-del-25', '/condiciones-generales-cupon-nos-importas-descuento-del-15', '/condiciones-generales-cupon-nos-importas-descuento-del-10', '/mondoconvenienzaday', '/mondoconvenienzaday', '/encuestas', '/condiciones-generales-mcday2020', '/condizioni-generali-mcday2020', '/404-sondaggi', '/encuesta-sofas', '/ministore-foggia', '/muebles-y-cocinas-madrid', '/coronavirus', '/promozione-materassi', '/rebajas-2020', '/ministore-palermo', '/muebles-y-cocinas-para-tu-mundo', '/palermo', '/promozione-fai-da-te', '/promozione-fai-da-te-catania'] }
              exact
              render={ (props) => <NoFollow { ...props } /> }
            />,
            position: 550,
        },
        {
            component: <Route
              path="/stato-del-tuo-ordine"
              exact
              render={ (props) => (
                    <Gtm pageType="account">
                        <StatoTuoOrdine { ...props } />
                    </Gtm>
              ) }
            />,
            position: 551,
        },
        {
            component: <Route
              path={ ['/survey/acquisto-ecommerce', '/survey/trasportoemontaggio-ecommerce'] }
              exact
              render={ (props) => (
                    <Gtm pageType="other">
                        <SurveyAcquistoEcommerce { ...props } />
                    </Gtm>
              ) }
            />,
            position: 552,
        },
        {
            component: <Route
              path="/sitemap"
              exact
              render={ (props) => <Sitemap { ...props } /> }
            />,
            position: 553,
        },
        {
            component: <Route
              path="/promozione-camerette-coupon"
              exact
              render={ (props) => (
                    <Gtm pageType="other">
                        <PromozioneCameretteCupon { ...props } />
                    </Gtm>
              ) }
            />,
            position: 554,
        },
        {
            component: <Route
              path="/promozione-camere-coupon"
              exact
              render={ (props) => (
                    <Gtm pageType="other">
                        <PromozioneCamereCupon { ...props } />
                    </Gtm>
              ) }
            />,
            position: 555,
        },
        {
            component: <Route
              path="/concorsocatanzaro"
              exact
              render={ (props) => (
                    <Gtm pageType="other">
                        <ConcorsoCatanzaro { ...props } />
                    </Gtm>
              ) }
            />,
            position: 556,
        },
        {
            component: <Route
              path="/lp-mcday2022"
              exact
              // eslint-disable-next-line no-return-assign
              render={ () => (
                  window.location.href = '/'
              ) }
            />,
            position: 557,
        },
        {
            component: <Route
              path="/super-saldi-arredamento"
              exact
              render={ (props) => (
                    <Gtm pageType="other">
                        <SuperSaldiArredamento { ...props } />
                    </Gtm>
              ) }
            />,
            position: 558,
        },
        // {
        //     component: <Route
        //       path="/mcday"
        //       exact
        //       render={ (props) => (
        //             <Gtm pageType="other">
        //                 <McDay2024 { ...props } />
        //             </Gtm>
        //       ) }
        //     />,
        //     position: 559
        // },
        {
            component: <Route
              path="/offerte.html"
              exact
              render={ () => (history.push('/offerte/miglior-prezzo.html')) }
            />,
            position: 560,
        },
        {
            component: <Route
              path="/procedura-whistleblowing"
              exact
              render={ (props) => (
                    <Whistleblowing { ...props } />
              ) }
            />,
            position: 561,
        },
        // CONFIGURATORE CUCINE
        {
            component: <Route
              path={ withStoreRegex('/configuratore-cucine') }
              exact
              render={ (props) => (
                    <ConfiguratoreCucine { ...props } />
              ) }
            />,
            position: 562,
        },
        {
            component: <Route
              path={ withStoreRegex('/dolce-casa') }
              exact
              render={ (props) => (
                    <Gtm pageType="appuntamento">
                        <DolceCasa isLanding { ...props } />
                    </Gtm>
              ) }
            />,
            position: 563,
        },
        {
            component: <Route
              path={ withStoreRegex('/thank-you-page-findomestic') }
              exact
              render={ (props) => (
                    <Gtm pageType="checkout">
                        <ThankYouPageFindo { ...props } />
                    </Gtm>
              ) }
            />,
            position: 564,
        },
        {
            component: <Route
              path={ withStoreRegex('/collezioni/:family/') }
              exact
              render={ (props) => (
                    <Gtm pageType="catalogo">
                        <Collezioni { ...props } />
                    </Gtm>
              ) }
            />,
            position: 565,
        },
        {
            component: <Route
              path={ withStoreRegex('/collezioni/:family/:collection') }
              exact
              render={ (props) => (
                    <Gtm pageType="catalogo">
                        <CollezioniDettaglio { ...props } />
                    </Gtm>
              ) }
            />,
            position: 566,
        },
        // ANGOLO DELLE OCCASIONI PAGE
        {
            component: <Route
              path={ withStoreRegex('/angolo-delle-occasioni') }
              exact
              render={ (props) => (
                    <Gtm pageType="catalogo">
                        <AngoloDelleOccasioni { ...props } />
                    </Gtm>
              ) }
            />,
            position: 567,
        },
        {
            component: <Route
              path={ withStoreRegex('/trasporto-e-montaggio-gratis') }
              exact
              // eslint-disable-next-line no-return-assign
              render={ () => (
                  window.location.href = '/'
              ) }
            />,
            position: 568,
        },
        {
            component: <Route
              path={ ['/camere/accessori-armadio-1.html', '/camere/interni-armadio.html'] }
              exact
              // eslint-disable-next-line no-return-assign
              render={ () => (
                  window.location.href = '/interni-armadi'
              ) }
            />,
            position: 569,
        },
        {
            component: <Route
              path={ withStoreRegex('/interni-armadi') }
              exact
              render={ (props) => (
                    <Gtm pageType="catalogo">
                        <LandingInterniArmadi { ...props } />
                    </Gtm>
              ) }
            />,
            position: 570,
        },
        {
            component: <Route
              path={ withStoreRegex('/collezione-sedie-interno-esterno-design/') }
              exact
              render={ (props) => (
                    <Gtm pageType="catalogo">
                        <LandingPageSedie { ...props } />
                    </Gtm>
              ) }
            />,
            position: 570,
        }, {
            component: <Route
              path={ withStoreRegex('/success-page-dolce-casa') }
              exact
              render={ (props) => (
                    <Gtm pageType="checkout">
                        <SuccessPageDolceCasa { ...props } />
                    </Gtm>
              ) }
            />,
            position: 572,
        },
        // CONFIGURATORE DIVANI
        {
            component: <Route
              path={ withStoreRegex('/configuratore-divani') }
              exact
              render={ (props) => (
                    <ConfiguratoreDivani { ...props } />
              ) }
            />,
            position: 573,
        },
        {
            component: <Route
              path={ withStoreRegex('/configuratore-materassi') }
              exact
              render={ (props) => (
                    <ConfiguratoreMaterassi { ...props } />
              ) }
            />,
            position: 573,
        },
        // CONFIGURATORE DIVANI
        {
            component: <Route
              path={ withStoreRegex('/configurador-de-sofas') }
              exact
              render={ (props) => (
                    <ConfiguratoreDivani { ...props } />
              ) }
            />,
            position: 573,
        },
    ];
}

export default RouterComponent;
